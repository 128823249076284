import { ClientFunctionalities } from "modules/agents/clients/models/client";
import func from "modules/agents/functionalities";
import { GeneralIcons } from "modules/common/components/planr/icon/Generalcon";
import { routes } from "modules/common/routes";
import { default as dictionaries, default as functionalities } from "modules/dictionaries/functionalities";
import expenses from "modules/expenses/functionalities";
import { MenuButton } from "modules/orders-manage/details/MenuButton";
import { MenuTools } from "modules/orders-manage/details/MenuTools";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import orders from "modules/orders-manage/functionalities";
import { OrderListType } from "modules/orders-manage/models/order-list";
import { can } from "modules/session/auth/access";
import { EmployeeFunctionalities } from "modules/spending/employee/models/employer";
import spending from "modules/spending/functionalities";
import report from "modules/reports/functionalities";
import technical from "modules/technical/functionalities";
import { KnowledgeBaseTypes } from "modules/technical/models/store";

const dicts = routes.dictionaries;

export const menuItems = (
  access?: string[],
  ordersStore?: OrderListType,
  agentsRequestCount?: string,
  isMobile?: boolean,
  setHideMenu?: () => void
): MenuItems => {
  const orderId = ordersStore ? ordersStore.latestOrderId : "";
  // const canWorkResultRead = can(ClientFunctionalities.CLIENTS_USER_ACCESS, access ?? []);
  // let resultPreview =  canWorkResultRead ? {
  //     id: "result-preview",
  //     label: "Результат работы",
  //     icon: "general-dot",
  //     path: orderId ? routes.orders.details(orderId, OrderTabs.workPreview) : "",
  //     functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_CONTENT_TASK_READ}`,
  // } : null;

  const orderTableRoute = can(EmployeeFunctionalities.INTERNAL_USER_ACCESS, access ?? []) ? routes.orders.manage : "";
  const AgentsTableRoute = can(func.OUTSOURCER_READ, access ?? []) ? routes.agents.outsourcers.outsourcers : "";

  const clientAccess = can(ClientFunctionalities.CLIENTS_USER_ACCESS, access ?? []);
  return clientAccess
    ? {
        newOrder: {
          id: "new",
          label: "",
          path: "",
          functionality: "",
          icon: "general-transparent",
          labelLeft: ordersStore ? MenuButton(ordersStore) : undefined,
        },

        main: { id: "main", label: "Главная", path: routes.main.path, icon: "general-dashboard" },
        orders: {
          id: "orders",
          label: "Управление договорами",
          path: orderTableRoute,
          handler: orderTableRoute ? undefined : (expanded) => ordersStore?.toggleVisibility(expanded),
          icon: "general-doc",
          labelLeft: ordersStore ? MenuTools(ordersStore, isMobile, setHideMenu) : undefined,
          nested: {
            summary: {
              id: "summary",
              label: "Основные данные",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.summary) : "",
              functionality: `${orders.ORDERS_SUMMARY_EDIT},${orders.ORDERS_SUMMARY_READ}`,
            },
            finance: {
              id: "finance",
              label: "Финансирование",
              path: orderId ? routes.orders.details(orderId, OrderTabs.finance) : "",
              icon: "general-dot",
              functionality: `${orders.ORDERS_FINANCE_EDIT},${orders.ORDERS_FINANCE_READ}`,
            },
            content: {
              id: "content",
              label: "Состав проекта",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.content) : "",
              functionality: `${orders.ORDERS_CONTENTS_EDIT},${orders.ORDERS_CONTENTS_READ}`,
            },
            project: {
              id: "project",
              label: "Проект",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.project) : "",
              functionality: `${orders.ORDERS_CONTENT_TASK_EDIT},${orders.ORDERS_CONTENT_TASK_READ}`,
            },
            resultPreview: {
              id: "result-preview",
              label: "Результат работы",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.workPreview) : "",
              functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_CONTENT_TASK_READ}`,
            },
            ipd: {
              id: "ipd",
              label: "ИРД",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.ipd) : "",
              functionality: `${orders.ORDERS_IPD_EDIT},${orders.ORDERS_IPD_READ}`,
            },
            orderTechIndicators: {
              id: "orderTechIndicators",
              label: "ТЭП",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderTechIndicators) : "",
              functionality: `${functionalities.ORDERINDICATOR_READ}, ${functionalities.ORDERINDICATOR_EDIT}`,
            },
            orderAgents: {
              id: "orderAgents",
              label: "Агенты",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderAgents) : "",
              functionality: `${orders.ORDERS_AGENTS_EDIT},${orders.ORDERS_AGENTS_READ}`,
            },
            orderConfirms: {
              id: "orderConfirms",
              label: "Подтверждения изменений",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderConfirm) : "",
              functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_CONFIRM_EDIT},${orders.ORDERS_CONFIRM_READ}`,
            },
            orderCommunication: {
              id: "orderCommunication",
              label: "Лист коммуникаций",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderCommunication) : "",
              functionality: `${orders.ORDERS_COMMUNICATION_READ}, ${orders.ORDERS_COMMUNICATION_EDIT}`,
            },
            spending: {
              id: "spending",
              label: "Состав затрат",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.spending) : "",
              functionality: `${orders.ORDERS_SPENDING_EDIT},${orders.ORDERS_SPENDING_READ}`,
            },
            mails: {
              id: "mails",
              label: "Корреспонденция",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.mails) : "",
              functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_MAILS_EDIT},${orders.ORDERS_MAILS_READ}`,
            },

            techTask: {
              id: "tech-task",
              label: "ТЗ",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.techTask) : "",
              functionality: `${orders.ORDERS_TECHNICAL_TASK_EDIT},${orders.ORDERS_TECHNICAL_TASK_READ}`,
            },
          },
        },
        spending: {
          id: "spending",
          label: "Сотрудники",
          path: "",
          icon: "general-users",
          nested: {
            schedule: {
              id: "schedule",
              label: "Штатное расписание",
              icon: "general-dot",
              path: routes.spending.schedule,
              functionality: spending.SCHEDULE_READ,
            },
            unit: {
              id: "unit",
              label: "Unit",
              icon: "general-dot",
              path: routes.spending.unit,
              functionality: spending.WORKOUT_UNIT,
            },
            timesheet: {
              id: "timesheet",
              icon: "general-dot",
              label: "Табель",
              path: routes.spending.timesheet,
              functionality: `${spending.TIMESHEET_EDIT},${spending.TIMESHEET_EDIT_DEPARTMENT},${spending.TIMESHEET_EDIT_USER}`,
            },
            workload: {
              id: "workload",
              icon: "general-dot",
              label: "Табель План",
              path: routes.spending.workload,
              functionality: spending.WORKLOAD_EDIT,
            },
            departments: {
              id: "departments",
              label: "Структура организации",
              icon: "general-dot",
              path: routes.spending.department.path,
              functionality: spending.DEPARTMENT_READ,
            },
            employee: {
              id: "employee",
              label: "Сотрудники",
              icon: "general-dot",
              path: routes.spending.employee.path,
              functionality: spending.EMPLOYER_READ,
            },
          },
        },
        reports: {
          id: "reports",
          label: "Отчеты",
          path: "",
          icon: "general-doc-clean",
          nested: {
            employerSpending: {
              id: "employer-spending",
              label: "Трудозатраты",
              icon: "general-dot",
              path: routes.reports.employerSpending,
              functionality: spending.REPORTS_READ,
            },
            orderYear: {
              id: "order-year",
              label: "Проекты за год",
              icon: "general-dot",
              path: routes.reports.orderYear,
              functionality: spending.REPORTS_READ,
            },
          },
        },
        expenses: {
          id: "expenses",
          label: "Расходы",
          path: routes.expenses.path,
          functionality: expenses.SPENDINGS,
          icon: "general-wallet",
          nested: {
            overheadSpendings: {
              id: "overhead-spendings",
              label: "Накладные расходы",
              icon: "general-dot",
              path: routes.expenses.overhead.path,
              functionality: expenses.SPENDINGS,
            },
            importSpendings: {
              id: "import-spendings",
              label: "Импорт",
              icon: "general-dot",
              path: routes.expenses.import,
              functionality: expenses.SPENDINGS_IMPORT,
            },
            financeRequests: {
              id: "finance-requests",
              label: "Фин. запросы",
              icon: "general-dot",
              path: routes.financeRequests.path,
              functionality: `${expenses.FINANCE_REQUEST_ACCEPT},${expenses.FINANCE_REQUEST_PAY}`,
            },
          },
        },
        agents: {
          id: "agents",
          label: "Контрагенты",
          path: AgentsTableRoute,
          icon: "general-contractor",
          functionality: `${func.OUTSOURCER_EDIT},${func.OUTSOURCER_READ}`,
          //   nested: {
          //       outsourcers: {
          //           id: "agents",
          //           label: "Аутсорсеры",
          //           icon: "general-dot",
          //           path: routes.agents.outsourcers.outsourcers,
          //           functionality: agents.OUTSOURCER_READ,
          //       },
          //       clients: {
          //           id: "clients",
          //           label: "Заказчики",
          //           icon: "general-dot",
          //           path: routes.agents.clients.path,
          //           functionality: agents.CLIENT_READ,
          //       },
          //       suppliers: {
          //           id: "suppliers",
          //           label: "Поставщики",
          //           icon: "general-dot",
          //           path: routes.agents.suppliers.path,
          //           functionality: agents.SUPPLIER_READ,
          //       },
          //   },
        },
        dictionaries: {
          id: "dictionaries",
          label: "Справочники",
          path: "",
          icon: "general-doc",
          nested: {
            inventory: {
              id: "inventory",
              label: "Материальная база",
              icon: "general-dot",
              path: dicts.inventory.path,
              functionality: dictionaries.INVENTORY_READ,
            },
            productionStages: {
              id: "production-stages",
              label: "Стадии строительства",
              icon: "general-dot",
              path: dicts.productionStage.path,
              functionality: dictionaries.PRODUCTIONSTAGE_READ,
            },
            ipdTypes: {
              id: "ipd-types",
              label: "ИРД",
              icon: "general-dot",
              path: dicts.ipdType.path,
              functionality: dictionaries.IPDTYPE_READ,
            },
            agentsCategories: {
              id: "agents-categories",
              label: "Категории агентов",
              icon: "general-dot",
              path: dicts.agentsCategories.path,
              functionality: dictionaries.AGENTSCATEGORIES_READ,
            },
            orderIndicators: {
              id: "order-indicators",
              label: "ТЭП",
              icon: "general-dot",
              path: dicts.orderIndicator.path,
              functionality: dictionaries.ORDERINDICATOR_READ,
            },
            orderStatuses: {
              id: "order-stratuses",
              label: "Статусы договоров",
              icon: "general-dot",
              path: dicts.orderStatus.path,
              functionality: dictionaries.ORDERSTATUS_READ,
            },
            orderTypes: {
              id: "order-types",
              label: "Типы договоров",
              icon: "general-dot",
              path: dicts.orderType.path,
              functionality: dictionaries.ORDERTYPE_READ,
            },
            workTypes: {
              id: "work-types",
              label: "Виды работ",
              icon: "general-dot",
              path: dicts.workType.path,
              functionality: dictionaries.WORKTYPE_READ,
            },
            projectPortfolio: {
              id: "project-portfolios",
              label: "Портфели проектов",
              icon: "general-dot",
              path: dicts.projectPortfolio.path,
              functionality: dictionaries.PROJECTPORTFOLIO_READ,
            },
            bankDetais: {
              id: "bank-details",
              label: "Реквизиты",
              icon: "general-dot",
              path: dicts.bankDetails.path,
              functionality: dictionaries.BANKDETAILS_READ,
            },
          },
        },
        technical: {
          id: "technical",
          label: "Корпоративные документы",
          path: "",
          icon: "general-corpdoc",
          nested: {
            manuals: {
              id: "manuals",
              label: KnowledgeBaseTypes.manuals,
              path: routes.technical.manuals,
              functionality: technical.TECHICAL_MANUALS_READ,
              icon: "general-dot",
            },
            standards: {
              id: "standards",
              label: KnowledgeBaseTypes.standards,
              path: routes.technical.standards,
              functionality: technical.TECHICAL_QUALITY_STANDARDS_READ,
              icon: "general-dot",
            },
            templates: {
              id: "templates",
              label: "Шаблоны документов",
              path: routes.technical.templates,
              functionality: technical.TECHICAL_TEMPLATES_READ,
              icon: "general-dot",
            },
            organisations: {
              id: "organisations",
              label: KnowledgeBaseTypes.organisations,
              path: routes.technical.organisations,
              functionality: technical.TECHICAL_ORGANISATIONS_READ,
              icon: "general-dot",
            },
            orders: {
              id: "orders",
              label: KnowledgeBaseTypes.orders,
              path: routes.technical.orders,
              functionality: technical.TECHICAL_ORDERS_READ,
              icon: "general-dot",
            },
          },
        },
        mails: {
          id: "mails",
          label: "Корреспонденция",
          icon: "general-msg",
          path: routes.orders.mails,
          functionality: `${orders.ORDERS_MAILS_EDIT},${orders.ORDERS_MAILS_READ}`,
        },
        orderConfirms: {
          id: "orderConfirms",
          label: "Подтверждения изменений",
          icon: "general-dot",
          path: routes.orders.orderConfirmsTab,
          functionality: `${orders.ORDERS_CONFIRM_EDIT},${orders.ORDERS_CONFIRM_READ}`,
        },
      }
    : {
        newOrder: {
          id: "new",
          label: "",
          path: "",
          functionality: "",
          icon: "general-transparent",
          labelLeft: ordersStore ? MenuButton(ordersStore) : undefined,
        },

        main: { id: "main", label: "Главная", path: routes.main.path, icon: "general-dashboard" },
        orders: {
          id: "orders",
          label: "Управление договорами",
          path: orderTableRoute,
          handler: orderTableRoute ? undefined : (expanded) => ordersStore?.toggleVisibility(expanded),
          icon: "general-doc",
          labelLeft: ordersStore ? MenuTools(ordersStore, isMobile, setHideMenu) : undefined,
          nested: {
            summary: {
              id: "summary",
              label: "Основные данные",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.summary) : "",
              functionality: `${orders.ORDERS_SUMMARY_EDIT},${orders.ORDERS_SUMMARY_READ}`,
            },
            finance: {
              id: "finance",
              label: "Финансирование",
              path: orderId ? routes.orders.details(orderId, OrderTabs.finance) : "",
              icon: "general-dot",
              functionality: `${orders.ORDERS_FINANCE_EDIT},${orders.ORDERS_FINANCE_READ}`,
            },
            content: {
              id: "content",
              label: "Состав проекта",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.content) : "",
              functionality: `${orders.ORDERS_CONTENTS_EDIT},${orders.ORDERS_CONTENTS_READ}`,
            },
            project: {
              id: "project",
              label: "Проект",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.project) : "",
              functionality: `${orders.ORDERS_CONTENT_TASK_EDIT},${orders.ORDERS_CONTENT_TASK_READ}`,
            },

            ipd: {
              id: "ipd",
              label: "ИРД",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.ipd) : "",
              functionality: `${orders.ORDERS_IPD_EDIT},${orders.ORDERS_IPD_READ}`,
            },
            orderTechIndicators: {
              id: "orderTechIndicators",
              label: "ТЭП",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderTechIndicators) : "",
              functionality: `${functionalities.ORDERINDICATOR_READ}, ${functionalities.ORDERINDICATOR_EDIT}`,
            },
            orderAgents: {
              id: "orderAgents",
              label: "Агенты",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderAgents) : "",
              functionality: `${orders.ORDERS_AGENTS_EDIT},${orders.ORDERS_AGENTS_READ}`,
            },
            orderConfirms: {
              id: "orderConfirms",
              label: "Подтверждения изменений",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderConfirm) : "",
              functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_CONFIRM_EDIT},${orders.ORDERS_CONFIRM_READ}`,
            },
            orderCommunication: {
              id: "orderCommunication",
              label: "Лист коммуникаций",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.orderCommunication) : "",
              functionality: `${orders.ORDERS_COMMUNICATION_READ}, ${orders.ORDERS_COMMUNICATION_EDIT}`,
            },
            spending: {
              id: "spending",
              label: "Состав затрат",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.spending) : "",
              functionality: `${orders.ORDERS_SPENDING_EDIT},${orders.ORDERS_SPENDING_READ}`,
            },

            mails: {
              id: "mails",
              label: "Корреспонденция",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.mails) : "",
              functionality: `${ClientFunctionalities.CLIENTS_USER_ACCESS},${orders.ORDERS_MAILS_EDIT},${orders.ORDERS_MAILS_READ}`,
            },

            techTask: {
              id: "tech-task",
              label: "ТЗ",
              icon: "general-dot",
              path: orderId ? routes.orders.details(orderId, OrderTabs.techTask) : "",
              functionality: `${orders.ORDERS_TECHNICAL_TASK_EDIT},${orders.ORDERS_TECHNICAL_TASK_READ}`,
            },
          },
        },
        spending: {
          id: "spending",
          label: "Сотрудники",
          path: "",
          icon: "general-users",
          nested: {
            schedule: {
              id: "schedule",
              label: "Штатное расписание",
              icon: "general-dot",
              path: routes.spending.schedule,
              functionality: spending.SCHEDULE_READ,
            },
            unit: {
              id: "unit",
              label: "Unit",
              icon: "general-dot",
              path: routes.spending.unit,
              functionality: spending.WORKOUT_UNIT,
            },
            timesheet: {
              id: "timesheet",
              icon: "general-dot",
              label: "Табель",
              path: routes.spending.timesheet,
              functionality: `${spending.TIMESHEET_EDIT},${spending.TIMESHEET_EDIT_DEPARTMENT},${spending.TIMESHEET_EDIT_USER}`,
            },

            workload: {
              id: "workload",
              icon: "general-dot",
              label: "Табель План",
              path: routes.spending.workload,
              functionality: spending.WORKLOAD_EDIT,
            },
            departments: {
              id: "departments",
              label: "Структура организации",
              icon: "general-dot",
              path: routes.spending.department.path,
              functionality: spending.DEPARTMENT_READ,
            },
            employee: {
              id: "employee",
              label: "Сотрудники",
              icon: "general-dot",
              path: routes.spending.employee.path,
              functionality: spending.EMPLOYER_READ,
            },
          },
        },
        reports: {
          id: "reports",
          label: "Отчеты",
          path: "",
          icon: "general-doc-clean",
          nested: {
            employerSpending: {
              id: "employerSpending",
              label: "Трудозатраты",
              icon: "general-dot",
              path: routes.reports.employerSpending,
              functionality: report.EMPLOYER_SPENDING_REPORTS_READ,
            },
            orderYear: {
              id: "orderYear",
              label: "Проекты за год",
              icon: "general-dot",
              path: routes.reports.orderYear,
              functionality: report.ORDER_YEAR_REPORTS_READ,
            },
          },
        },
        expenses: {
          id: "expenses",
          label: "Расходы",
          path: routes.expenses.path,
          functionality: expenses.SPENDINGS,
          icon: "general-wallet",
          nested: {
            overheadSpendings: {
              id: "overhead-spendings",
              label: "Накладные расходы",
              icon: "general-dot",
              path: routes.expenses.overhead.path,
              functionality: expenses.SPENDINGS,
            },
            importSpendings: {
              id: "import-spendings",
              label: "Импорт",
              icon: "general-dot",
              path: routes.expenses.import,
              functionality: expenses.SPENDINGS_IMPORT,
            },
            financeRequests: {
              id: "finance-requests",
              label: "Фин. запросы",
              icon: "general-dot",
              path: routes.financeRequests.path,
              functionality: `${expenses.FINANCE_REQUEST_ACCEPT},${expenses.FINANCE_REQUEST_PAY}`,
            },
          },
        },
        agents: {
          id: "agents",
          label: "Контрагенты",
          pin1: `${agentsRequestCount}`,
          path: AgentsTableRoute,
          icon: "general-contractor",
          functionality: `${func.OUTSOURCER_EDIT},${func.OUTSOURCER_READ}`,
          //   nested: {
          //       outsourcers: {
          //           id: "agents",
          //           label: "Аутсорсеры",
          //           icon: "general-dot",
          //           path: routes.agents.outsourcers.outsourcers,
          //           functionality: agents.OUTSOURCER_READ,
          //       },
          //       clients: {
          //           id: "clients",
          //           label: "Заказчики",
          //           icon: "general-dot",
          //           path: routes.agents.clients.path,
          //           functionality: agents.CLIENT_READ,
          //       },
          //       suppliers: {
          //           id: "suppliers",
          //           label: "Поставщики",
          //           icon: "general-dot",
          //           path: routes.agents.suppliers.path,
          //           functionality: agents.SUPPLIER_READ,
          //       },
          //   },
        },
        dictionaries: {
          id: "dictionaries",
          label: "Справочники",
          path: "",
          icon: "general-doc",
          nested: {
            inventory: {
              id: "inventory",
              label: "Материальная база",
              icon: "general-dot",
              path: dicts.inventory.path,
              functionality: dictionaries.INVENTORY_READ,
            },
            productionStages: {
              id: "production-stages",
              label: "Стадии строительства",
              icon: "general-dot",
              path: dicts.productionStage.path,
              functionality: dictionaries.PRODUCTIONSTAGE_READ,
            },
            orderIndicators: {
              id: "order-indicators",
              label: "ТЭП",
              icon: "general-dot",
              path: dicts.orderIndicator.path,
              functionality: dictionaries.ORDERINDICATOR_READ,
            },
            ipdTypes: {
              id: "ipd-types",
              label: "ИРД",
              icon: "general-dot",
              path: dicts.ipdType.path,
              functionality: dictionaries.IPDTYPE_READ,
            },
            agentsCategories: {
              id: "agents-categories",
              label: "Категории агентов",
              icon: "general-dot",
              path: dicts.agentsCategories.path,
              functionality: dictionaries.AGENTSCATEGORIES_READ,
            },
            orderStatuses: {
              id: "order-stratuses",
              label: "Статусы договоров",
              icon: "general-dot",
              path: dicts.orderStatus.path,
              functionality: dictionaries.ORDERSTATUS_READ,
            },
            orderTypes: {
              id: "order-types",
              label: "Типы договоров",
              icon: "general-dot",
              path: dicts.orderType.path,
              functionality: dictionaries.ORDERTYPE_READ,
            },
            workTypes: {
              id: "work-types",
              label: "Виды работ",
              icon: "general-dot",
              path: dicts.workType.path,
              functionality: dictionaries.WORKTYPE_READ,
            },
            projectPortfolio: {
              id: "project-portfolios",
              label: "Портфели проектов",
              icon: "general-dot",
              path: dicts.projectPortfolio.path,
              functionality: dictionaries.PROJECTPORTFOLIO_READ,
            },
            bankDetais: {
              id: "bank-details",
              label: "Реквизиты",
              icon: "general-dot",
              path: dicts.bankDetails.path,
              functionality: dictionaries.BANKDETAILS_READ,
            },
          },
        },
        technical: {
          id: "technical",
          label: "Корпоративные документы",
          path: "",
          icon: "general-corpdoc",
          nested: {
            manuals: {
              id: "manuals",
              label: KnowledgeBaseTypes.manuals,
              path: routes.technical.manuals,
              functionality: technical.TECHICAL_MANUALS_READ,
              icon: "general-dot",
            },
            standards: {
              id: "standards",
              label: KnowledgeBaseTypes.standards,
              path: routes.technical.standards,
              functionality: technical.TECHICAL_QUALITY_STANDARDS_READ,
              icon: "general-dot",
            },
            templates: {
              id: "templates",
              label: "Шаблоны документов",
              path: routes.technical.templates,
              functionality: technical.TECHICAL_TEMPLATES_READ,
              icon: "general-dot",
            },
            organisations: {
              id: "organisations",
              label: KnowledgeBaseTypes.organisations,
              path: routes.technical.organisations,
              functionality: technical.TECHICAL_ORGANISATIONS_READ,
              icon: "general-dot",
            },
            orders: {
              id: "orders",
              label: KnowledgeBaseTypes.orders,
              path: routes.technical.orders,
              functionality: technical.TECHICAL_ORDERS_READ,
              icon: "general-dot",
            },
          },
        },
        mails: {
          id: "mails",
          label: "Корреспонденция",
          icon: "general-msg",
          path: routes.orders.mails,
          functionality: `${orders.ORDERS_MAILS_EDIT},${orders.ORDERS_MAILS_READ}`,
        },
        orderConfirms: {
          id: "orderConfirms",
          label: "Подтв изменений",
          icon: "general-exclamation",
          iconSmall: true,
          path: routes.orders.orderConfirmsTab,
          functionality: `${orders.ORDERS_CONFIRM_EDIT},${orders.ORDERS_CONFIRM_READ}`,
        },
      };
};

export interface MenuItem {
  id: string;
  label: string;
  pin1?: string;
  pin2?: string;
  labelLeft?: (access: string[]) => any;
  path: string;
  nested?: TStringMap<MenuItem>;
  functionality?: string;
  icon?: GeneralIcons;
  iconSmall?: boolean;
  handler?: (expanded: boolean) => void;
}

export interface MenuItems extends TStringMap<MenuItem> {
  main: MenuItem;
  orders: OrdersMenuItem;
  spending: SpendingMenuItem;
  expenses: ExpensesMenuItem;
  reports: ReportsMenuItem;
  agents: MenuItem;
  dictionaries: DictionariesMenuItem;
  technical: TechnicalMenuItem;
  mails: MenuItem;
  orderConfirms: MenuItem;
}

interface ExpensesMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    overheadSpendings: MenuItem;
    importSpendings: MenuItem;
    financeRequests: MenuItem;
  };
}

interface ReportsMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    employerSpending: MenuItem;
    orderYear: MenuItem;
  };
}
interface SpendingMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    schedule: MenuItem;
    unit: MenuItem;
    timesheet: MenuItem;
    workload: MenuItem;
    departments: MenuItem;
    employee: MenuItem;
  };
}

interface OrdersMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    summary: MenuItem;
    finance: MenuItem;
    content: MenuItem;
    project: MenuItem;
    orderTechIndicators: MenuItem;
    orderAgents: MenuItem;
    orderCommunication: MenuItem;
    ipd: MenuItem;
    spending: MenuItem;
    mails: MenuItem;
    orderConfirms: MenuItem;
    techTask: MenuItem;
  };
}

interface AgentsMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    outsourcers: MenuItem;
    clients: MenuItem;
    suppliers: MenuItem;
  };
}

interface DictionariesMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    workTypes: MenuItem;
    orderTypes: MenuItem;
    orderStatuses: MenuItem;
    productionStages: MenuItem;
    ipdTypes: MenuItem;
    agentsCategories: MenuItem;
    orderIndicators: MenuItem;
    inventory: MenuItem;
    projectPortfolio: MenuItem;
    bankDetais: MenuItem;
  };
}

interface TechnicalMenuItem extends MenuItem {
  nested: TStringMap<MenuItem> & {
    manuals: MenuItem;
    standards: MenuItem;
    templates: MenuItem;
    organisations: MenuItem;
    orders: MenuItem;
  };
}
